import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useMutation, useNotify, useQueryWithStore } from "ra-core";
import React from "react";
import { Delivery, Driver } from "../../../types";
import { deliveryActions } from "../util";

interface Props {
  record: Delivery;
}
interface AutoCompleteProps {
  full_name: string;
  id?: number;
}
const AssignDriverDialog: React.FC<Props> = (props) => {
  const { record } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const { data: drivers } = useQueryWithStore({
    type: "getList",
    resource: "drivers",
    payload: { sort: { field: "full_name", order: "asc" } },
  });
  const [selectedDriver, setSelectedDriver] = React.useState<AutoCompleteProps>(
    {
      full_name: "",
    }
  );

  // initialise the value of the autocomplete
  React.useEffect(() => {
    if (!drivers) return;

    const tempDrivers: Driver[] = drivers;
    const theDriver = tempDrivers.find(
      (driver) => driver.id === record.driver_id
    );
    if (theDriver) setSelectedDriver(theDriver);
  }, [drivers, record]);

  const handleClose = () => setOpen(false);

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "deliveries",
      payload: {
        id: record.id ?? "",
        data: {
          action: deliveryActions.ASSIGN,
          driver_id: selectedDriver.id ?? "",
        },
      },
    },
    {
      onSuccess: ({ data }) => {
        notify(`Successfully assign a Driver to the Booking.`, "info");
        setOpen(false);
      },
      onFailure: (error) => {
        notify(`Error: ${error.message}`, "warning");
        setOpen(true);
      },
    }
  );

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} size={"small"}>
        Assign Driver
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Assign Driver</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a driver to be assigned to this Delivery Order.
          </DialogContentText>
          <Autocomplete
            disableClearable
            id="select-driver"
            options={drivers}
            getOptionLabel={(driver: { full_name: string }) => driver.full_name}
            renderInput={(params) => <TextField {...params} label="Driver" />}
            onChange={(event, value: any) => setSelectedDriver(value)}
            value={selectedDriver}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignDriverDialog;
