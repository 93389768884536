import Icon from "@material-ui/icons/LocalShipping";
import { FC } from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  Filter,
  List,
  ListProps,
  NumberInput,
  Record,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import {
  AllocationIdField,
  BookingIdField,
  DeliveryIdField,
  ProductInstanceIdField,
} from "../../components/fields";
import DeliveryLocationField from "../../components/fields/DeliveryLocationField";
import MediaListField from "../../components/MediasListField";
import { foundIn } from "../../utils";
import { convertJsToSql } from "../../utils/date";
import AssignMultipleDriverDialog from "./actions/AssignMultipleDriverDialog";
import DeliveryActions from "./DeliveryActions";
import { deliveryStatus } from "./util";

const ResourceList: FC<ListProps> = (props) => {
  // const [isAssigning, setIsAssigning] = useState<boolean>(true);
  // const [selectedDriver, setSelectedDriver] = useState<number>(-1);

  const isRowSelectable = (record: Record): boolean => {
    return foundIn(record.status, [
      deliveryStatus.NEW,
      deliveryStatus.ASSIGNED,
    ]);
  };

  return (
    <List
      {...props}
      sort={{ field: "id", order: "DESC" }}
      exporter={false}
      filters={<ListFilter />}
      bulkActionButtons={<AssignMultipleDriverDialog />}
      title="Deliveries"
    >
      <Datagrid isRowSelectable={isRowSelectable}>
        <DeliveryIdField />
        <BookingIdField source="booking" />
        <ProductInstanceIdField source="product_instance" />
        <TextField
          source="product_instance.location.name"
          label="Current Position"
        />
        <TextField source="target_location.name" label="Target Location" />
        <DateField source="target_delivery_time" showTime={true} />
        <TextField source="driver.full_name" label="Driver" />
        <DeliveryLocationField
          locationIdSource="picked_location_id"
          timeSource="picked_time"
          label="Picked Up At"
        />
        <DeliveryLocationField
          locationIdSource="delivered_location_id"
          timeSource="delivered_time"
          label="Drop Off At"
        />
      </Datagrid>
    </List>
  );
};

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <SelectInput
      source="status"
      choices={[
        { id: deliveryStatus.NEW, name: "New" },
        { id: deliveryStatus.DELIVERED, name: "Delivered" },
        { id: deliveryStatus.ASSIGNED, name: "Assigned" },
        { id: deliveryStatus.PICKED, name: "Picked" },
        { id: deliveryStatus.CANCELLED, name: "Cancelled" },
      ]}
    />
    <TextInput source="booking_id" />
    <TextInput source="product_instance_id" label="Set" />
    <ReferenceInput
      label="Target Location"
      source="target_location_id"
      reference="locations"
    >
      <AutocompleteInput
        optionText="name"
        // shouldRenderSuggestions={(val: string) => {
        //   return val.trim().length > 2;
        // }}
      />
    </ReferenceInput>

    <DateInput
      Label="Target delivery time"
      source="target_delivery_time"
      showTime={true}
    />
    <ReferenceInput
      label="Driver"
      source="driver_id"
      reference="drivers"
      sort={{ field: "name", order: "ASC" }}
    >
      <AutocompleteInput
        optionText="full_name"
        shouldRenderSuggestions={(val: string) => {
          return val.trim().length > 2;
        }}
      />
    </ReferenceInput>
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        mode="show"
        resourceId={props.id}
      />
      <Show {...props} actions={<DeliveryActions />}>
        <SimpleShowLayout>
          <DeliveryIdField />
          <ProductInstanceIdField source="product_instance" />
          <TextField source="note_to_driver" />
          <TextField
            source="product_instance.location.name"
            label="Current Location"
          />
          <TextField source="target_location.name" label="Target Location" />
          <DateField source="target_delivery_time" showTime={true} />
          <ReferenceField source="driver_id" reference="drivers">
            <TextField source="full_name" />
          </ReferenceField>
          <DateField source="picked_time" showTime />
          <DeliveryLocationField
            locationIdSource="picked_location_id"
            timeSource="picked_time"
            label="Picked Up At"
          />
          <DeliveryLocationField
            locationIdSource="delivered_location_id"
            timeSource="delivered_time"
            label="Drop Off At"
          />
          <MediaListField
            source="delivered_medias"
            label="Delivery Attachments"
          />
          <TextField source="delivered_note" label="Delivery Note" />

          <hr />
          <BookingIdField source="booking" />
          <AllocationIdField source="allocation" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        mode="create"
        resourceId=""
      />
      <Create {...props}>
        <SimpleForm>
          <NumberInput source="booking_id" />
          <NumberInput source="allocation_id" />
          <ReferenceInput
            source="product_instance_id"
            reference="product-instances"
            label="From"
          >
            <AutocompleteInput optionText="location.name" disabled />
          </ReferenceInput>
          <ReferenceInput
            source="target_location_id"
            reference="locations"
            label="To"
            filter={{ is_virtual: false }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <DateTimeInput source="target_delivery_time" parse={convertJsToSql} />
          <ReferenceInput
            source="product_instance_id"
            reference="product-instances"
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <TextInput multiline source="note_to_driver" label="Note To Driver" />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "deliveries",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  create: ResourceCreate,
  list: ResourceList,
  show: ResourceShow,
};
