import React from "react";
import { SelectInput, useQueryWithStore } from "react-admin";
import { BusinessUnit } from "../../types";

interface Props {
  source: string;
}
const BusinessUnitInput: React.FC<Props> = (props) => {
  const [options, setOptions] = React.useState([]);
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "business-units",
    payload: { sort: { field: "name", order: "asc" } },
  });

  React.useEffect(() => {
    if (!data) return;

    const mapped = data.map((businessUnit: BusinessUnit) => ({
      id: businessUnit.id,
      name: businessUnit.name,
    }));
    setOptions(mapped);
  }, [data]);

  return <SelectInput {...props} choices={options} />;
};

export default BusinessUnitInput;
