import Icon from "@material-ui/icons/AllInbox";
import { capitalize } from "lodash";
import React, { FC } from "react";
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  Edit,
  EditProps,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useDataProvider,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import { Location, Product } from "../../types";
import ResourceList from "./list";
import ResourceShow from "./show";

const ResourceEdit: FC<EditProps> = (props) => {
  const { products } = Common.useMyHook();
  const IssueEditToolbar: FC<any> = (props) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props} title={`Set Box #${props.id} Edit`}>
        <SimpleForm redirect="show" toolbar={<IssueEditToolbar />}>
          <TextField source="id" />
          <TextInput source="name" label="Set" />
          <SelectInput
            source="product_id"
            choices={products}
            label="Product Name"
          />
          <BooleanInput source="is_consigned" label="Consignment" />
          <SelectInput
            source="status"
            choices={[
              { id: "active", name: "Active" },
              { id: "inactive", name: "Inactive" },
            ]}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  const { products, locations } = Common.useMyHook();

  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props} title={"Create Set Box"}>
        <SimpleForm>
          <TextInput source="name" label="Set" />
          <AutocompleteInput
            source="product_id"
            choices={products}
            label="Product Name"
          />
          <AutocompleteInput source="location_id" choices={locations} />
          <BooleanInput source="is_consigned" label="Consignment" />
        </SimpleForm>
      </Create>
    </>
  );
};

export const Common = {
  useMyHook: () => {
    const [products, setProducts] = React.useState<Product[]>([]);
    const [locations, setLocations] = React.useState<Location[]>([]);
    const dataProvider = useDataProvider();
    // load products
    React.useEffect(() => {
      dataProvider
        .getList("products", {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "name", order: "asc" },
          filter: { status: "active" },
        })
        .then(({ data }) => setProducts(data as Product[]));
    }, [dataProvider]);

    // load locations
    React.useEffect(() => {
      dataProvider
        .getList("locations", {
          pagination: { page: 1, perPage: 1000 },
          sort: { field: "name", order: "asc" },
          filter: { status: "active", is_virtual: false },
        })
        .then(({ data }) => setLocations(data as Location[]));
    }, [dataProvider]);

    return { products, locations };
  },
  resourceName: "product-instances",
  baseBreadCrumb: [
    {
      link: `/product-instances`,
      title: `${capitalize("product-instances")}`,
    },
  ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  create: ResourceCreate,
  edit: ResourceEdit,
};
