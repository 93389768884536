import Box from "@material-ui/core/Box";
import React, { FC } from "react";
import {
  DateField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import bookings from ".";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import { BookingIdField } from "../../components/fields";
import { Booking } from "../../types";
import Allocate from "./allocations/Allocate";
import { ShowActions } from "./BookingActions";
import BookingItems from "./BookingItems";
import BookingRevision from "./BookingRevision";
import Delivery from "./Deliveries";
import ReportItems from "./ReportItems";

export const BookingShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={bookings.name}
        mode="show"
        resourceId={props.id}
      />
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label="summary">
            <BookingIdField />
            <TextField source="consultant.full_name" label="Consultant" />

            <DateField source="date" showTime={true} />
            <TextField source="surgeon.full_name" label="Surgeon" />

            <TextField source="location.name" label="Location" />
            <TextField source="procedure.name" label="Procedure" />

            <TextField source="additional_items" />

            <TextField source="remarks" />

            <TextField source="invoice_number" />
            <BookingRevision />
          </Tab>
          <Tab label="Booking Items">
            <BookingItems {...props} />
          </Tab>
          <Tab label="Allocations">
            <Allocations />
          </Tab>
          <Tab label="Deliveries">
            <Delivery />
          </Tab>
          {/* We don't need chat tabs for now */}
          {/* <Tab label="Chat">
            <Chat />
          </Tab> */}
          <Tab label="Reports">
            <ReportItems {...props} />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

interface TabContentProps<T> {
  record?: T;
}

/**
 * Allocation window
 *
 * @param props
 * @returns
 */
const Allocations: React.FC<TabContentProps<Booking>> = (props) => {
  const { record: booking } = props;

  if (!booking) {
    return <Box>Loading Booking...</Box>;
  }

  return <Allocate bookingId={booking.id} />;
};
